.header {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    color: rgb(236, 223, 223);
}

.links {
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    font-family: 'Jura', sans-serif;
    font-weight: bold;
}

.links p:hover {
    text-decoration: underline;
}

.links p {
    margin: 1em;
}

.links p a {
    color: rgb(236, 223, 223);
    text-decoration: none;
    font-size: 20px;
}

h1 a {
    text-decoration: none;
    color: rgb(236, 223, 223);
}

.content {
    background-color: rgba(24, 36, 39);
    color: rgb(236, 223, 223);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    font-family: 'PT Serif', serif;
    padding: 4em;
    border-radius: 7px;
}


.contentHeader {
    font-family: 'Didact Gothic', sans-serif;
    font-weight: bold;
}

.igLogo {
    height: 5em;
    width: 5em;
    margin: 10px;
}

form {
    color: rgb(236, 223, 223);
    display: flex;
    flex-direction: column;
}

body {
    background-image: url('./galleryImages/Becoming_an_idea.png')
}

.nameLink {
    font-family: 'Montserrat', sans-serif;
    margin-left: 1em;
}

.emailText {
    width: 50em;
    height: 27em;
}

.gridSquare {
    height: auto;
    max-width: 55%;
    margin: 2em;
  }

.gridSquare:hover {
    opacity: .5;
}

.galleryContainer {
    display: flex;
    flex-direction: row;
    align-items: center ;
    width: 100%;
    justify-content: center;
}

.artDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.artDetails p {
    margin: .2em;
    text-align: left;
}

.artTitle {
    width: 100%;
    height: 100%;
}

.adminSection {
    background-color: cornsilk;
    border-radius: 5px;
    width: 100%;
    margin: 1em;
}

.adminSectionHeader {
    color: rgba(24, 36, 39, 0.911);
    font-family: jura;
    border-bottom: 1px solid rgba(24, 36, 39, 0.911);
}

.adminController {
    background-color: red;
    color: green;
}

.modal {
    background: rgba(0, 0, 0, 0.9);
    max-width: auto;
    max-height: auto;
}

.modalImage {
    max-height: 90%;
    width: auto;
}

.modalCloseButton:hover {
    color: red;
    text-decoration: underline;
}

.burgerLinks {
    background-color: rgba(72, 97, 89, 0.9);
    position: fixed;
    left: 20%;
    top: 20%;
    right: 50%;
    bottom: 50%;
    width: 70%;
    max-width: 90%;
    height: 30%;
    border-radius: 7px;

}

.burgerLinks div {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 7px;

}

.burgerLinks div a {
    text-decoration: none;
    color: whitesmoke;
    font-family: 'Jura', sans-serif;
    font-weight: bold;
    border-radius: 7px;

}

.burgerMenu {
    width: 30px;
    height: 30px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-direction: column;
    padding: none;
    margin: none;
    border-radius: 7px;
}

.burgerMenu div {
    padding: none;
    margin: none;
    width: 100%;
    height: 5px;
    background-color:  rgb(236, 223, 223);
    border-radius: 7px;
}
/* 
info under imgs
Didact Gothic

Name font
Julius sans one

Writing in artist statement
Sorts Mill Goudy



font-family: 'Didact Gothic', sans-serif;
font-family: 'Julius Sans One', sans-serif;
font-family: 'Sorts Mill Goudy', serif;
*/